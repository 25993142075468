.card {
  padding: 10px;
  border-radius: 25px;
  margin-bottom: 15px;
  box-shadow: 2px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #169dea;
  background-color: white;
}

.install-card {
  width: 100%;
  margin: 10px 0 10px;
}

.auth-card {
  width: 100%;
}

.confirm-card {
  width: 90%;
}

.new-password {
  padding-top: 20px;
}

.card-header {
  background-color: #169dea;
  padding: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: calc(100% + 20px);
  margin: -10px -10px 10px -10px;
  box-sizing: border-box;
}

.card-header-logo {
  max-width: 16.5em;
  margin: 0 auto;
  display: block;
}

.responder-card {
  display: flex;
  align-items: center;
}

.notfound-card {
  margin-top: 20px;
}