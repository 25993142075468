.checkbox-container {
    --input-focus: #2d8cf0;
    --input-out-of-focus: #ccc;
    --bg-color: #fff;
    --bg-color-alt: #666;
    --main-color: #323232;
    position: relative;
    display: flex;
    align-items: center;
}

.checkbox-container small {
    margin-left: 8px;
}
  
.checkbox-container input {
    position: absolute;
    opacity: 0;
}
  
.checkmark {
    width: 20px;
    height: 20px;
    position: relative;
    top: 0;
    left: 0;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    background-color: var(--input-out-of-focus);
    transition: all 0.3s;
}

.show-all {
    width: 15px;
    height: 15px;
}
  
.checkbox-container input:checked ~ .checkmark {
    background-color: var(--input-focus);
}
  
.checkmark:after {
    content: "";
    width: 4px;
    height: 10px;
    position: absolute;
    top: 2px;
    left: 8px;
    display: none;
    border: solid var(--bg-color);
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
}

.show-all:after {
    width: 4px;
    height: 7px;
    left: 5px;
}
  
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}