.install-container {
    width: 80%;
    display: flex;
    align-items: center;
    height: 100vh;
    margin: auto;
}

.install-info-text {
    font-size: 14px;
}

.ios-info {
    border: 3px solid #15ff00 !important;
}

.ios-info ul {
    list-style-type: none;
    padding: 0;
    font-size: 14px;
}

.ios-info li {
    margin-bottom: 10px;
}

/* Installation ready styles */
.install-ready {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.install-checkmark-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
  
.install-checkmark {
    width: 56px;
    height: 56px;
}
  
.install-checkmark-circle {
    stroke: #4CAF50;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-linecap: round;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards, fill 0.4s ease-in-out 0.6s forwards;
}
  
.install-checkmark-check {
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.7s forwards;
}
  
@keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
}
  
@keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #4CAF50;
    }
}
  
@keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
}

.install-ready-text {
    font-weight: bold;
    margin-bottom: 0;
}

@media (min-width: 900px) {
    .install-container {
        width: 50%;
    }
}