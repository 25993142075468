.auth-container {
  width: 80%;
  display: flex;
  align-items: center;
  height: 100vh;
  margin: auto;
}

form {
  margin: 20px;
}
  
.auth-container label {
  font-weight: bold;
  margin-bottom: 10px;
}
  
.auth-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
}

.input-container-password {
  position: relative;
  display: flex;
  align-items: center;
}

.password-info {
  font-size: 12px;
  color: #797979; 
}

.password-icon {
  position: absolute;
  right: 0.5rem;
  padding-bottom: 0.8rem;
  color: #3d3d3d;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
  display: none;
}

.password-change-success {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

.auth-error {
  margin-top: 0;
  margin-bottom: 10px;
}

.auth-phone {
  margin: 5px 0;
}

.resend-code-container {
  font-size: 0.8rem;
  margin: 5px 0 15px;
}

.resend-code {
  margin-bottom: 5px;
}

.resend-link {
  color: #169dea;
  text-decoration: underline;
  cursor: pointer;
}

.radius-container {
  display: flex;
  align-items: center;
}

.radius-info {
  color: #169dea;
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}

.radius-info-popup {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  font-weight: normal;
}

.radius-info-popup.show {
  max-height: 150px;
  opacity: 1;
}

.radius-input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.radius-input {
  width: 20%;
  padding: 5px;
  margin-right: 5px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.disabled {
  opacity: 0.5;
}

.verification-code-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.verification-code-input {
  width: 30px;
  height: 40px;
  margin: 5px 5px 20px 0;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.external-icon {
  margin-left: 5px;
}

@media (min-width: 900px) {
  .auth-container {
    width: 50%;
  }
  form {
    padding-left: 10%;
    padding-right: 10%;
  }
}