.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: width 0.2s, height 0.2s;
  font-size: 1rem;
  color: #333;
}

.loading-indicator .fa-spinner {
  font-size: 2rem;
}