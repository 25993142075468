/* Common for all modals */
.modal {
  border-radius: 20px;
}

.modal-container {
  background-color: #e9f3ff;
  background-image: linear-gradient(to bottom right, #d2e6ff, #f4f9ff);
  height: 100%;
  border-radius: 20px;
}

.modal-content-full {
  overflow: auto;
  max-height: 90vh;
}

.modal-content {
  margin: 0 20px;
}

.top-bar-modal {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #169dea;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 20px 20px 0 0;
}

.modal-form {
  margin: 0;
}

.intertitle {
  padding-top: 10px;
  font-weight: bold;
  text-decoration: underline;
}

.char-counter {
  font-size: 12px;
  color: #666;
  margin-left: 5px;
}

select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: black;
  background-color: white;
}

.select-wrapper {
  border-radius: 25px;
  display: inline-block;
  overflow: hidden;
  background:#cccccc;
  border: 1px solid #cccccc;
  width: 70%;
  box-shadow: 2px 6px rgba(0, 0, 0, 0.2);
}

.select-wrapper-location {
  width: 90%;
}

.select-wrapper-location-request {
  width: 99%;
  margin-bottom: 10px;
}

.dropdown {
  border: 0px;
  outline: none;
}

.text-input {
  margin-bottom: 10px;
  margin-left: 5px;
  border-radius: 25px;
  box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
  max-width: 38%;
  padding: 4px 4px;
}

.radius {
  width: 10%;
  text-align: center;
  margin-right: 5px;
}

.phone-disabled {
  opacity: 0.5;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
}

.spacer1 {
  padding-bottom: 10px;
}

.spacer2 {
  margin-bottom: 5px;
}

.spacer3 {
  margin-bottom: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.usernamefield {
  background-color: #f8f8f8;
  border-radius: 4px;
}

.phonefield {
  background-color: #f8f8f8;
  border-radius: 4px;
}

textarea {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  resize: none;
  font-family: 'Nunito', sans-serif;
  border-radius: 25px;
  padding: 10px;
}

.inactive-edit {
  opacity: 0.2;
}
  
.active-edit {
  opacity: 1;
}

/* RequestModal */

.category-icon {
  margin-left: 10px;
}

.request-user-and-status {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.phonelink {
  color: #169dea;
  text-decoration: none;
}

.responder-info {
  display: flex;
  flex-direction: column;
}

.test-request {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

.admin-buttons {
  margin-top: 20px;
}

/* Settings Modal */

ul {
  padding: 0;
}

.list-item {
  list-style: none;
}

.list-header {
  display: flex;
  align-items: center;
}

.list-header .icon-left {
  margin-right: 10px;
}

.list-header .icon-right {
  margin-left: auto;
}

.settings-slider {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  font-weight: normal;
}

.settings-slider.show {
  margin-top: 10px;
  max-height: 500px;
  opacity: 1;
}

.infopage-link {
  text-decoration: none;
  color: black;
}

.user-count {
  position: absolute;
  bottom: 0;
  font-weight: bold;
}