.status-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.modal-circle {
  margin-left: 5px;
}
  
.status-red {
  background-color: red;
}
  
.status-yellow {
  background-color: yellow;
}
  
.status-green {
  background-color: green;
}

.status-outline-red {
  border: 1px solid red;
}
    
.status-outline-green {
  border: 1px solid green;
}
    
.status-outline-yellow {
  border: 1px solid rgb(255, 208, 0);
}