.big-button {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: #169dea;
    border-radius: 25px;
    border-color: black;
    color: white;
    padding: 15px 32px;
    font-size: 16px;
    box-shadow: 2px 6px rgba(0, 0, 0, 0.2);
}

.install {
    background-color: #15ff00;
    color: black;
    font-weight: bold;
}

.login-button, 
.register-button, 
.next-button {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.newuser-button {
    padding: 10px;
    color: white;
    box-sizing: border-box;
    background-color: white;
    border: #169dea 1px solid;
    color: black;
    font-size: 12px;
}

.new-request {
    width: 50%;
    margin-bottom: 4%;
}

.removeresponder {
    padding: 2px;
    background-color: #ff0000;
    color: white;
    width: 25%;
    font-size: 12px;
    margin-left: auto;
}

.edit {
    margin-top: 10%;
    padding: 10px 20px;
    font-size: 12px;
    width: 70%;
    margin-bottom: 5px;
}

.desc {
    max-width: 70%;
    font-size: 12px;
    padding: 10px 20px;
}

.settings-edit,
.settings-save {
    font-size: 11px;
    width: 26%;
    padding: 4px 4px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 5px;
}

.settings-cancel,
.account-delete {
    font-size: 11px;
    width: 26%;
    padding: 4px 4px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 5px;
    background-color: #ff0000;
    color: white;
}

.account-delete {
    margin-top: 5%;
}

.password-change {
    font-size: 11px;
    width: 40%;
    padding: 4px 4px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 5px;
    background-color: #169dea;
    color: white;
}

.update-location {
    margin-top: 0;
}

.ready {
    margin-top: 10%;
    background-color: rgb(149, 255, 122);
    border-color: green;
    color: black;
}

.removeresponder-self {
    background-color: #ff0000;
}

.workingonit {
    color: black;
    background-color: #ffc400;
    opacity: 0.5;
}

.cancel {
    background-color: #ff0000;
    border-radius: 25px;
    border-color: black;
    color: white;
}

.logout {
    margin-top: 20%;
    background-color: white;
    border-color: red;
    color: red;
}

.delete {
    margin-top: 10%;
    background-color: #ff0000;
    color: white;
}

.confirmation-cancel {
    background-color: #169dea;
    border-color: black;
    color: white;
    padding: 10px 10px;
    margin-right: 10%;
    font-size: 12px;
}

.confirmation-confirm {
    border-color: black;
    color: white;
    padding: 10px 10px;
    font-size: 12px;
}

.confirmation-cancel-red {
    background-color: #ff0000;
    border-color: black;
    color: white;
    padding: 10px 10px;
    margin-right: 10%;
    font-size: 12px;
}

.mark-as-ready,
.takeover {
    background-color: rgb(149, 255, 122);
    border-color: green;
    color: black;
}

.warning {
    background-color: #ff0000;
    color: white;
}

.pswreg {
    font-size: small;
    text-align: center;
    display: block;
    margin-top: 10px;
    text-decoration: underline;
}

.modal-close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #169dea;
    font-size: 24px;
    cursor: pointer;
    border-radius: 25px;
    z-index: 9999;
}

.page-close {
    margin: 16px 0px;
    color: #169dea;
    font-size: 24px;
    cursor: pointer;
    border-radius: 25px;
    z-index: 9999;
}

/* Big screen */
@media (min-width: 900px) {
    .install,
    .login-button,
    .newuser-button,
    .next-button {
        width: 50%;
        margin-left: 25%;
    }
    .settings-edit,
    .settings-save,
    .settings-cancel,
    .password-change,
    .account-delete {
        width: 20%;
    }
}

@media (min-width: 1600px) {
    .big-button {
        width: 30%;
    }
    .install,
    .login-button,
    .newuser-button,
    .next-button {
        width: 50%;
        margin-left: 25%;
    }
    .ready,
    .send-request {
        padding: 15px;
    }
}