.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.top-bar {
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 7%;
    background-color: #169dea;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.top-bar-sides {
    display: none;
}

.main-page-logo {
    max-width: 18em;
}

.settings {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.content {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
}

.requests-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.show-all-text {
    margin-left: 5px;
}
  
.bottom {
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.modal {
    animation: slide-up 0.2s ease-out forwards;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 95%;
    height: 95%;
    background-color: white;
    border-radius: 10px;
    transform: translate(-50%, -50%);
}
  
@keyframes slide-up {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
}

/* Big screen */
@media (min-width: 900px) {
    .top-bar {
        box-shadow: 0px 0px 0px;
    }
    .top-bar-sides {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 110px;
        background-color: #169dea;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);
        z-index: -9999;
    }
    .container {
        width: 50%;
        margin: 0 auto;
    }
    .main-page-logo {
        max-width: 25em;
    }
    .modal {
        width: 40%;
    }
}