.request-container {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  margin-bottom: 25px !important;
}
  
.own-request {
  background-color: #ecdaff;
  background-image: linear-gradient(to top left, #ecdaff, #f7f0ff);
}

.responding-request {
  background-color: #daffec;
  background-image: linear-gradient(to top left, #daffec, #f0f7ff);
}

.request-item {
  margin: 8px 0px;
}

.listIcon {
  margin-right: 5px;
}

.category {
  font-weight: bold;
}

.updated {
  grid-column: span 2;
}

.no-requests{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 70%;
}

.empty-icon {
  font-size: 3rem;
  color: #169dea;
}