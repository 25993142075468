.top-bar-info {
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #169dea;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.email {
  font-size: 0.9em;
}

.copybutton {
  background-color: #169dea;
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 11px;
}

.privacystatement-content h4 {
  margin-bottom: 5px;
}

.privacystatement-content p {
  margin: 10px 0;
}

.privacystatement-list {
  margin-left: 20px;
}

.privacystatement-list li {
  margin-bottom: 5px;
}

/* Big screen */
@media (min-width: 900px) {
  .top-bar-info {
      box-shadow: 0px 0px 0px;
  }
  .top-bar-info-sides {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 70px;
      background-color: #169dea;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);
      z-index: -9999;
  }
}