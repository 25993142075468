body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #169dea;
  background-image: linear-gradient(to bottom right, #d2e6ff, #f4f9ff);
}

.no-scroll {
  overflow: hidden;
  touch-action: none;
}

.text-spacer {
  margin-right: 4px;
}

.success {
  margin-top: 10px;
  font-weight: bold;
  color: green;
}

.error {
  margin-top: 10px;
  color: red;
}