.map {
  width: 100%;
  height: 100%;
  margin-top: 5px;
}

.own-location-marker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.own-location-marker-outer {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 122, 255, 0.2);
  border-radius: 50%;
  position: absolute;
}

.own-location-marker-inner {
  width: 15px;
  height: 15px;
  background-color: #169dea;
  border-radius: 50%;
  border: 3px solid white;
}

.marker-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
}

.marker-text {
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
}